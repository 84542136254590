$(function initLeftNav() {
    $('.leftNav_c .root-level-item li').each(function(i, e) {
        var $e = $(e);

        if(!$e.children('ul').length) return;

        if($e.hasClass('active')) {
            $($e.append('<a class="collapse fa fa-chevron-down" data-collapse="expanded">'));
            $e.children('ul').show();
        } else {
            $($e.append('<a class="collapse fa fa-chevron-right" data-collapse="collapsed">'));
            $e.children('ul').hide();
        }
    });

    $('.leftNav_c').on('click', '.collapse', function(evt) {
        evt.preventDefault();

        var $e = $(this);
        var $parent = $e.parent();

        if($e.attr('data-collapse') === 'collapsed') {
            $parent.children('ul').show();
            $(this).removeClass('fa-chevron-right').addClass('fa-chevron-down').attr('data-collapse', 'expanded');
        } else if($e.attr('data-collapse') === 'expanded') {
            $parent.children('ul').hide();
            $(this).removeClass('fa-chevron-down').addClass('fa-chevron-right').attr('data-collapse', 'collapsed');
        }
    });

    $('.leftNav_c li').each(function(i, e) {
        var newDate;
        for (var i = 0; i < e.classList.length; i ++) {
            if (e.classList[i].indexOf('newItem-') === 0) {
                newDate = e.classList[i].substr('newItem-'.length);
            }
        }

        if (!newDate) return;

        var dateParts = newDate.split('-');
        if (!newDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            if (window.CMS.page_mode === 'prev') {
                $(e).append($('<div class="previewError" />').text('newItem class with date "' + newDate + '" is not valid. Use yyyy-mm-dd format.'));
            }
            return;
        }

        var newUntil = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
        newUntil.setDate(newUntil.getDate() + 30);

        if (newUntil >= (new Date())) {
            $(e).addClass('newItem');
        }
    });
});