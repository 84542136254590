$(function() {
    if (CMS.user_type === 'Guest') {
        return;
    }

    if (!window.networkMap) {
        console.error('Could not find networks map. Does the page extend ocms_Networks and contain the networks JSON?');
        return;
    }

    // Want to check URLs with a site prefix before the non-prefixed version
    var tryOrder = Object.keys(window.networkMap).sort(function(a, b) {
        var aDomain = a.split('/')[0];
        var bDomain = b.split('/')[0];

        var aHasPathPrefix = !!a.split('/')[1];
        var bHasPathPrefix = !!b.split('/')[1];

        if(aDomain < bDomain) return -1;
        if(aDomain > bDomain) return 1;

        if(aHasPathPrefix && !bHasPathPrefix) return -1;
        if(!aHasPathPrefix && bHasPathPrefix) return 1;
        return 0;
    });

    $('body').on('click', 'a', function(evt) {
        var href = $(evt.target).attr('href')

        if (href.indexOf('https') !== 0) return; // Not a secure external URL;

        var networkId = deriveNetworkId(href);

        if (!networkId) return; // Didn't match a known community

        var relativeUrl = deriveRelativeUrl(href);

        evt.target.href = buildSwitchUrl(networkId, relativeUrl);
    });

    function deriveNetworkId(url) {
        return tryOrder.reduce(function(acc, path) {
            if(url.indexOf('https://' + path) === 0) return window.networkMap[path];
            return acc;
        }, '');
    }

    function deriveRelativeUrl(url) {
        var relativeUrl = url.substr('https://'.length); // Remove https:// prefix
        if(relativeUrl.indexOf('/') === -1) {
            relativeUrl = '/';
        } else {
            relativeUrl = relativeUrl.substr(relativeUrl.indexOf('/')); // Remove domain up to first /
        }

        return relativeUrl;
    }

    function buildSwitchUrl(networkId, relativeUrl) {
        var newHref = '/';

        if (window.CMS.site_prefix !== null && window.CMS.page_mode === 'production') {
            newHref += CMS.site_prefix + '/';
        }

        newHref += 'servlet/networks/switch?networkId=' + networkId + '&startURL=' + encodeURIComponent(relativeUrl);

        return newHref;
    }
});