$(document).ocmsDynamicLoadFinished(function initTableOfContents() {
    var $devDocument = $('.devDocument[data-toc]');

    if($devDocument.length !== 0 && $('h2', $devDocument).length !== 0) {
        $devDocument.parents('.column').removeClass('width-9/12').addClass('width-7/12');
        var $tocColumn = $('<div class="column width-2/12">');
        $tocColumn.insertAfter($devDocument.parents('.column'));

        generateTableOfContents($devDocument, $tocColumn);
        initScrollSpy();
    }
});

function generateTableOfContents(container, tocColumn) {
    var headings = $('h2, h3, h4, h5, h6', container);

    var tocContainer = $('<nav class="tableOfContents" role="navigation"><div class="title">Contents</div></nav>');
    var rootList = $('<ol data-toc />').appendTo(tocContainer);
    var currentList = rootList;
    var currentLevel = 1;
    var headingNumbers = [null, 0, 0, 0, 0, 0];

    var usedIds = {};

    headings.each(function() {
        var heading = this;
        var headingText = $(heading).text();
        var slug = slugify(headingText);
        var headingLevel = getHeadingLevel(this) - 1; // Only looking at h2-6
        var propId = slug;
        var repeats = 0;
        while(usedIds[propId]) {
            repeats ++;
            propId = slug + '-' + repeats;
        }

        usedIds[propId] = true;

        $(heading).prop('id', propId);

        // Back out to continue an existing list if necessary
        while (headingLevel < currentLevel) {
            headingNumbers[currentLevel] = 0;
            currentList = currentList.parent().closest('ol');
            currentLevel --;
        }

        // Create a new child list if necessary
        if (headingLevel > currentLevel) {
            if (headingLevel > currentLevel + 1) {
                console.error(
                    'Learn to heading! Heading "' + headingText + '"'
                    + 'jumped from level ' + currentLevel + ' to ' + headingLevel
                );
            }

            currentList = $('<ol />').appendTo($('> li:last-child', currentList));
            currentLevel ++;
        }

        // Create the new item
        headingNumbers[currentLevel] ++;
        var sectionNumber = generateSectionNumber(headingNumbers, currentLevel);
        currentList.append(
            $('<li />').append(
                $('<a />').prop('href', '#' + propId).text(headingText)
            )
        );
    });

    tocColumn.empty().append(tocContainer);
}

function getHeadingLevel(element) {
    return parseInt(element.nodeName.substr(1));
}

function generateSectionNumber(headingNumbers, level) {
    var number = '';

    for (var i = 1; i <= level; i ++) {
        number += headingNumbers[i] + '.';
    }

    return number;
}

function slugify(str) {
    return str.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^[-\d]+/, '')         // Trim - and numbers from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

function initScrollSpy() {
    function updateScrollSpy() {
        var tocIds = [];

        $('.tableOfContents a[href]').each(function () {
            var href = $(this).attr('href');
            href.indexOf('#') === 0 && tocIds.push(href.substr(1));
        });

        var scrollStatus = tocIds.map(function (id) {
            var element = document.querySelector('#' + id);
            var position = 0;

            while (element && element != document.body) {
                position += (element.offsetTop - element.scrollTop + element.clientTop);
                element = element.offsetParent;
            }

            return {
                id: id,
                offset: position - document.documentElement.scrollTop - $('.header_c').height(),
            };
        }).reduce(function (scrollStatus, element) {
            if (element.offset < 0) {
                scrollStatus.prev = element.offset > scrollStatus.prev.offset ? element : scrollStatus.prev;
            } else {
                scrollStatus.next = element.offset < scrollStatus.next.offset ? element : scrollStatus.next;
            }

            return scrollStatus;
        }, {prev: {id: null, offset: -Infinity}, next: {id: null, offset: Infinity}});

        var activeTarget = scrollStatus.next.offset < 50 ? scrollStatus.next.id : scrollStatus.prev.id;

        $('.tableOfContents li').removeClass('active');

        $('.tableOfContents a[href]').filter(function () {
            return $(this).attr('href') === '#' + activeTarget;
        }).parents('li').addClass('active');
    }

    $(window).scroll((function () {
        var timeout;
        return function() {
            var later = function() {
                timeout = null;
                updateScrollSpy();
            };
            var callNow = !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, 60);
            if (callNow) updateScrollSpy();
        };
    })());
}