var interactionApi = require('../api/interactionApi');
var urlParameters = require('../util/urlParameters');

window.loginForms = window.loginForms || [];

$(function() {
    $('.loginForm').each(function() {
        window.loginForms.push(new LoginForm($(this)));
    });
});

function LoginForm($element) {
    this.$element = $element;

    $('form', this.$element).submit(this.handleLogin.bind(this));
}

LoginForm.prototype.handleLogin = function(evt) {
    evt.preventDefault();

    var loginForm = evt.target;

    var values = {};

    $('[name]', loginForm).each(function(i, e) {
        values[e.name] = e.value;
    });

    $('.error', loginForm).remove();
    $('button', loginForm).prop('disabled', true);

    var startUrl = urlParameters.getParameter('startURL') || '';

    interactionApi.doLogin(values.username, values.password, startUrl, function(result) {
        if (result.success) {
            var redirectUrl = result.message.target || '/';
            window.location.replace(redirectUrl);
        } else {
            $(loginForm).prepend(
                $('<p class="error">').text(result.message)
            );
        }

        $('button', loginForm).prop('disabled', false);
    });
};
