module.exports = function throttle(fn, freq) {
    var isThrottling = false;

    return function() {
        var args = arguments;
        var ctx = this;
        if (!isThrottling) {
            fn.apply(ctx, args);
            isThrottling = true;
            setTimeout(function() {
                isThrottling = false;
            }, freq);
        }
    }
};