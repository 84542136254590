var throttle = require('../util/throttle');

$(function() {
    $('iframe[src^="https://www.youtube.com/embed/videoseries"]').each(function(i, e) {
        initPlaylist(e);
    });

    $(window).on('resize', throttle(function() {
        $('.youtubePlaylist').each(function(i, e) {
            setMobileClass($(e));
        });
    }, 100));
});

function initPlaylist(iframe) {
    var $container = $('<div class="youtubePlaylist">');

    $container.append(
        '<div class="videoContainer"><div class="videoSizer" /></div>',
        '<div class="playlistWrapper"><div class="playlistContainer" /></div>'
    );

    var playlistId = iframe.src.match(/[?&]list=([^&]+)/)[1];

    window.doServiceRequest({
        service: 'ocms_GoogleApiService',
        action: 'restGet',
        url: 'https://www.googleapis.com/youtube/v3/playlistItems?playlistId=' + playlistId + '&maxResults=50&part=snippet%2CcontentDetails'
    }, {
        readonly: true,
        cb: function(result) {
            result = JSON.parse(result);

            var firstVideo = result.items[0].contentDetails.videoId;
            $('.videoSizer', $container).empty().append($('<iframe frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>').prop('src', 'https://youtube.com/embed/' + firstVideo));

            $('.playlistContainer', $container).append(result.items.map(function(item, idx) {
                return (
                    $('<div>').append(
                        $('<a class="videoLink' + (idx === 0 ? ' active' : '') + '">').prop('href', 'https://youtube.com/embed/' + item.contentDetails.videoId + '?autoplay=1').text(item.snippet.title)
                    )
                );
            }));
        }
    });

    $(iframe).replaceWith($container);

    $container.on('click', '.videoLink', function(evt) {
        evt.preventDefault();

        $('.videoSizer', $container).empty().append($('<iframe frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>').prop('src', evt.target.href));
        $('.playlistContainer .active', $container).removeClass('active');
        $(evt.target).addClass('active');
    });

    setMobileClass($container);
}

function setMobileClass($container) {
    if ($container.parent().width() < 768) {
        $container.addClass('mobile');
    } else {
        $container.removeClass('mobile');
    }
}