var doServiceRequest_crossSite = require('../../util/doServiceRequest_crossSite');

window.crossSiteLoaders = window.crossSiteLoaders || {};

$(function() {
    $('.crossSiteByName').each(function() {
        window.crossSiteLoaders[this.id] = new CrossSiteByName($(this));
    });
});

function CrossSiteByName($element) {
    this.$element = $element;

    this.siteName = this.$element.attr('data-sitename');
    this.contentName = this.$element.attr('data-contentname');
    this.contentLayout = this.$element.attr('data-contentlayout');

    this.renderingRequest = {
        listParameters: {
            contentNames: [ this.contentName ],
            contentLayouts: [ this.contentLayout ],
        },
        parameters: { renderType: 'contentName' },
        requestFlags: {},
    };

    this.request = {
        service: 'OrchestraRenderingAPI',
        action: 'getRenderedContent',
        renderingRequest: JSON.stringify(this.renderingRequest),
        apiVersion: '5.0',
    };

    this.update();
}

CrossSiteByName.prototype.update = function() {
    this.$element.empty().addClass('loading');
    doServiceRequest_crossSite(this.siteName, this.request, {
        readonly: true,
        cb: this.render.bind(this),
    });
};

CrossSiteByName.prototype.render = function(json) {
    this.$element.removeClass('loading');

    var result = JSON.parse(json);
    if (!result.isSuccess) {
        console.error(result);
        return;
    }

    var responseObject = JSON.parse(result.responseObject);

    this.$element.append(responseObject.renderings[0].renderMap[this.contentLayout]);
};
