var serviceName = 'OrchestraInteractionAPI';

function createResponseObject(result) {
    if (result.error) {
        return {
            success: false,
            message: result.error.message,
        };
    } else {
        return {
            success: true,
            message: result.success.message,
        }
    }
}

module.exports = {
    doChangePassword: function(oldPassword, newPassword, verifyNewPassword, cb) {
        var params = {
            service: serviceName,
            action: 'doChangePassword',
            oldPassword: oldPassword,
            newPassword: newPassword,
            verifyNewPassword: verifyNewPassword,
        };

        doServiceRequest(params, {
            readonly: false,
            cb: function(json) {
                var result = JSON.parse(json);
                cb(createResponseObject(result));
            },
        });
    },

    doLogin: function(username, password, startUrl, cb) {
        var params = {
            service: serviceName,
            action: 'doLogin',
            username: username,
            password: password,
            use_default_start: true,
            default_url: startUrl,
        };

        doServiceRequest(params, {
            readonly: true,
            cb: function(json) {
                var result = JSON.parse(json);
                cb(createResponseObject(result));
            },
        });
    }
};