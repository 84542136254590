var tingle = require('../../lib/tingle/tingle.js');
var cookie = require('../util/cookie');
var getNextId = require('../util/getNextId');

window.dialogs = {};

$(prepareAllDialogs);
$(document).ocmsDynamicLoadFinished(prepareAllDialogs);

function prepareAllDialogs() {
    $('[data-dialog]').each(function() {
        var $dialog = $(this);
        var dialogId = $dialog.prop('id');

        if (!dialogId || window.dialogs[dialogId] === undefined) {
            buildDialog($dialog);
        }
    });
}

function buildDialog($element) {
    var displayOptions = $element.attr('data-displayoptions');
    var dialogId = $element.prop('id') || getNextId('dialog');

    var dialogTrigger = $element.attr('data-dialogtrigger');

    if (displayOptions == 'daily' || displayOptions == 'session' || displayOptions == 'user') {
        if (cookie.get(dialogId) === displayOptions) return;
    }

    var closeMethods = ['button'];
    if ($element.attr('data-closeonescape') === 'true') {
        closeMethods.push('escape');
    }

    if ($element.attr('data-closeonoverlay') === 'true') {
        closeMethods.push('overlay');
    }

    var dialogProps = {
        footer: true,
        stickyFooter: false,
        closeMethods: closeMethods,
        closeLabel: 'Close',
        onOpen: function() {
            if (displayOptions == 'daily') {
                cookie.set(dialogId, displayOptions, 1);
            } else if (displayOptions == 'session') {
                cookie.set(dialogId, displayOptions);
            } else if (displayOptions == 'user') {
                cookie.set(dialogId, displayOptions, 1000);
            }
        }
    };

    var dialog = window.dialogs[dialogId] = new tingle.modal(dialogProps);
    $(dialog.modalBox).addClass('pg authorText');
    dialog.setContent($element.html());
    dialog.addFooterBtn('Ok', '', function() {
        dialog.close();
    });

    if (dialogTrigger === 'load') {
        setTimeout(function() {
            dialog.open();
        }, 0);
    } else if (dialogTrigger === 'timer') {
        var timerSeconds = $element.attr('data-dialogtimer');
        setTimeout(function() {
            dialog.open();
        }, timerSeconds * 1000);
    }
}