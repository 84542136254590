/* stantive.js */

var STG = STG || {};

$.extend(STG, {
    debugLevel: ($(document).data('cms') && $(document).data('cms').page_mode === 'production') ? 1 : 5        // log, debug, info, warn, error
    ,minDeviceWidthDesktop: 768		// non-retina iPad physical pixel width (portrait)
    ,isMobileLayout: undefined
    ,isTouchDevice: undefined
    ,doContentAnimations: true
});

STG.fDetectMobileLayout = function() {
    // isMobileLayout set true if window cannot meet a min-width of STG.minDeviceWidthDesktop
    STG.isMobileLayout = window.matchMedia && !window.matchMedia('(min-width: ' + STG.minDeviceWidthDesktop + 'px)').matches;
};	// fDetectMobileLayout


STG.fDetectTouchDevice = function() {
    STG.isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0;
};	// fDetectTouchDevice


STG.fHomeIntroAnimationForLaptop = function (nHack) {
    var $laptop = $('.orchestra .small-block-image img');
    if ($laptop.length) {
        // convenience reset operation for development if nHack === 0, always animate with jQuery if nHack === 1, else ignored
        if (nHack === 0) {
            $laptop.css({left: -900, opacity: 0}).removeClass('animateLaptop');
            $('.orchestra .actionButton').css({opacity: 0, left: 0}).removeClass('fadeIn');
        } else {
            if (!STG.doContentAnimations) {
                $laptop.css({opacity: 1, left: 0});
                $('.orchestra .actionButton').css({opacity: 1});
            } else {
                if (OCMS.browserSupportsTransition && nHack !== 1) {
                    $laptop.addClass('animateLaptop');
                    debug.info('animating laptop with CSS');

                    setTimeout(function() {
                        $('.orchestra .actionButton').addClass('fadeIn');
                    }, 900);
                } else {
                    debug.info('animating laptop with jQuery');
                    $laptop
                        .animate({left: 0}, {queue: false, duration: 900})
                        .delay(200)
                        .animate({opacity: 1}, {queue: false, duration: 900, easing: 'linear', complete: function fadeInButtons() {
                            $('.orchestra .actionButton').animate({opacity: 1}, 900);
                        }});
                }
            }
        }
    } else {
        debug.warn('could not find laptop image within OrchestraCMS blurb');
    }
};	// fHomeIntroAnimationForLaptop



STG.fHomePrepareSolutions = function() {
    if ($('.pgHome').length) {
        if (STG.doContentAnimations) {
            //debug.debug('fHomePrepareSolutions using waypoint...');
            // trigger when the solutions section fills the bottom 25% of the viewport
            $('.solutions').waypoint(STG.fHomeIntroAnimationForSolutions, {offset: '75%'});
        } else {
            STG.fHomeIntroAnimationForSolutions();
        }
    }
};	// fHomePrepareSolutions


STG.fRemoveFilterForIE8 = function() {
    if (OCMS.isIE8) {
        $(this).css('filter', '');
    }
}


STG.fHomeIntroAnimationForSolutions = function(nHack) {
    var $solns = $('.solutions .threeUp');

    if ($solns.length) {
        debug.debug('fHomeIntroAnimationForSolutions - start');

        // convenience reset operation for development if nHack === 0, always animate with jQuery if nHack === 1, else ignored
        if (nHack === 0) {
            $solns.find('img, .small-block-content').stop().css({opacity: 0}).removeClass('solFadeIn');
        } else {
            if (!STG.doContentAnimations) {
                $solns.find('img, .small-block-content').css({opacity: 1});
            } else {
                var jQueryAnimation = !OCMS.browserSupportsTransition || nHack === 1;
                debug.info('animating solutions introduction with ' + (jQueryAnimation ? 'jQuery' : 'CSS'));

                $solns.find('img').each(function(nIt, el) {
                    setTimeout(function() {
                        if (jQueryAnimation) {
                            $(el).animate({opacity: 1}, {queue: false, duration: 2100});
                        } else {
                            $(el).addClass('solFadeIn');
                        }
                    }, nIt * 250);
                });

                setTimeout(function() {
                    if (jQueryAnimation) {
                        // for IE8 jQuery uses filter: alpha(opacity=100) and zoom: 1
                        // unfortunately, presence of the filter creates horrendous typeface jaggies, so we have to remove
                        // it after transition completion
                        $solns
                            .find('.small-block-content')
                            .animate(
                                {opacity: 1},
                                {queue: false, duration: 800, complete: STG.fRemoveFilterForIE8}
                            );
                    } else {
                        $solns.find('.small-block-content').addClass('solFadeIn');
                    }
                }, 900);
            }
        }
    }

    // if called through waypoints, we’re one and done
    if (this.element) {
        this.destroy();
    }
};	// fHomeIntroAnimationForSolutions


/**
 * convert Vidyard meta-URL links into a link that will start playing an embedded video; this requires
 * requesting the Vidyard-based external JS resource for this specific video
 *
 * To process a link successfully, it must:
 *   	- have the class 'vidyard-meta'
 *    	- href be of the form '?vidyard={VIDYARD-ID}&optionalvalue=1'
 *     	- supported options include: &width, &height, &name_overlay=1
 *
 * Notes
 * 		- if &width is not specified in the meta-URL this adds a default width of 960 px
 * 		- if no link container is specified, this function restricts itself to looking within .authorText
 * 		  for links that need to be processed
 *
 * @param {jQuery} $container - optional container links must be within to match
 * @return undefined
 */
STG.fPrepareVidyardLinks = function ($container) {
    if ($container) {
        // valid if $container is instance of String, DOM, or jQuery
        $container = $($container);
    } else {
        $container = $('.authorText');
    }

    $('.vidyard-meta', $container).each(function _prepareVidyardLinks () {
        var $this = $(this),
            href = $this.attr('href'),
            vidYardCode,
            startOfOptions = href.indexOf('&'),
            options = '',
            script;

        if (href && href.slice(0, 9) === '?vidyard=') {
            // grag the Vidyard video embed id
            // '?vidyard=XYZ321'.match(/(=)([^&]+)/)  =>  ["=XYZ321", "=", "XYZ321"]
            vidYardCode = href.match(/(=)([^&]+)/)[2];
            if (startOfOptions !== -1) {
                options = href.slice(startOfOptions);
                // debug.debug('\tVidyard link options: ' + options);
            }

            // add a lightbox default width of 960 px if none already present
            if (!options.match(/&width=\d+/)) {
                options += '&width=960';
                // debug.debug('\tadding default lightbox width of 960');
            }

            // update link’s href to point to JS function to be provided by external Vidyard-hosted script
            $this.attr('href', 'javascript:fn_vidyard_' + vidYardCode + '(); // play video in lightbox');
            $this.removeClass('vidyard-meta').addClass('vidyardLink');

            //'<script type="text/javascript" class="vidyardScript" id="vidyard_embed_code_{VID-ID}" src="//play.vidyard.com/{VID-ID}.js?v=3.1.1&type=lightbox{OPTIONS}"></script>';
            script = document.createElement('script');
            script.type = 'text/javascript';
            script.className = 'vidyardScript';
            script.id = 'vidyard_embed_code_' + vidYardCode;
            script.src = '//play.vidyard.com/' + vidYardCode + '.js?v=3.1.1&type=lightbox' + options;
            document.body.appendChild(script);
        } else {
            debug.warn('link marked as vidyard-meta but has an incompatible href: ' + $this.text());
        }
    });
}	// fPrepareVidyardLinks


STG.fIntroAnimationForContactUs = function(direction) {
    if (!STG.doContentAnimations) {
        $('.contactUs').css({opacity: 1});
    } else {
        if (direction === 'down') {
            $('.contactUs').animate({opacity: 1}, {duration: 1100, complete: STG.fRemoveFilterForIE8});
        } else {
            debug.debug('introAnimationContactUs - up - doing nothing');
        }
    }

    // if called through waypoints, we’re one and done
    if (this.element) {
        this.destroy();
    }
};	// fIntroAnimationForContactUs


STG.fPrepareContactUsForm = function() {
    var $formContactUs = $('.contactUs form');

    if ($formContactUs.length) {
        // stripping inline styles for contact us form’s table and cells
        // since we’re here, give the table a class
        $formContactUs.find('table')
            .addClass('contactUs_t')
            .add('td').removeAttr('style');

        $formContactUs.find('input[type="submit"]').addClass('actionButton');

        if (STG.doContentAnimations) {
            debug.debug('fPrepareContactUsForm using waypoint...');
            // trigger when the solutions section fills the bottom 25% of the viewport
            $('.contactUs').waypoint(STG.fIntroAnimationForContactUs, {offset: '75%'});
        } else {
            STG.fIntroAnimationForContactUs();
        }

        // order matters since if supplantTable is called above it will have recreated the new structure using the original input value attributes
        // which we want to remove into only placeholder values
        $formContactUs.hintTextFromValues();
    }
};	// fPrepareContactUsForm


STG.fHomePrepareCaseStudies = function() {
    debug.debug('fHomePrepareCaseStudies');

    if (STG.doContentAnimations) {
        $('.caseStudies_c').waypoint(function introAnimationCaseStudies(direction) {
            if (direction === 'down') {
                $(this.element).animate({opacity: 1}, {duration: 1100, complete: STG.fRemoveFilterForIE8});
            } else {
                debug.debug('introAnimationCaseStudies - up - doing nothing');
            }
            this.destroy();		// one and done waypoint
        }, {offset: '70%'});
    } else {
        $('.caseStudies_c').css({opacity: 1});
    }

    if (STG.debugLevel >= 3) {
        $('.caseStudies_c').dblclick(function() {
            $('.caseStudies_c').animate({opacity: 0}, 500);
        });
    }
};	// fHomePrepareCaseStudies


STG.fCentreHeroImage = function(img) {
    $this = $(img);
    $this.css({left: ($this.parent().width() - $this.width()) / 2});
};	// fCentreHeroImage


STG.fPrepareHomePageTemplate = function() {
    // start laptop sliding in about 1.4s after page load complete
    setTimeout(STG.fHomeIntroAnimationForLaptop, 1400);

    /* Center the images when the page first loads, see waitAdjust() for details */
    $('.heroBanner img').each(function(nIt, img) {
        $(img).load(function() {
            STG.fCentreHeroImage(img);
        });
    });

    /* initialize skrollr for parallax scrolling effects */
    //var s = skrollr.init();

    STG.fHomePrepareSolutions();
    STG.fHomePrepareCaseStudies();

    $(window).resize(function centreAllHeroImages() {
        $('.heroBanner img').each(function(nIt, img) {
            STG.fCentreHeroImage(img);
        });
    });

    // magic green bar clicks for testing laptop and solution animations
    if (STG.debugLevel >= 3) {
        $('.greenBar').click(function(evt) {
            if (evt.pageX < 300) {
                // run the introductory animations
                debug.debug('relaunch animations…');
                evt.stopPropagation();
                evt.preventDefault();

                // hold down Cmd to force laptop animation to use jQuery
                STG.fHomeIntroAnimationForLaptop(evt.metaKey ? 1 : undefined);
                STG.fHomeIntroAnimationForSolutions();
            } else {
                debug.debug('reset animations…');
                // reset initial conditions
                evt.stopPropagation();
                evt.preventDefault();

                STG.fHomeIntroAnimationForLaptop(0);
                STG.fHomeIntroAnimationForSolutions(0);
            }
        });
    }
};	// fPrepareHomePageTemplate


/* @!todo 	we could replace this with a call to OCMS.smoothScrollTo() instead */
STG.fFocusOnContainer = function ($focusContainer) {
    var headerHeight = $('.header_c').css('position') === 'fixed' ? $('.header_c').outerHeight() + 5 : 0;

    $('html, body')
        .animate({scrollTop: $focusContainer.offset().top - headerHeight}, 'slow');

    return false;

    // evt.preventDefault();
    // evt.stopPropagation();
};	// fFocusOnContainer


// NOTE: no .pgSolutions anymore, just Solutions Content (teaserBlock and featureBlock on the General page template)

STG.fPrepareSolutionsPageTemplate = function () {
    // do featureBlocks first in order to have classname "featureN" on the nth feature for linking to by teaserBlock nextButton
    var $featureBlocks = $('.featureBlock');

    if ($featureBlocks.length) {
        // add a new class to features block in order to manage indexing
        $featureBlocks.each(function(index) {
            $(this).addClass('feature' + (index + 1));
        });

        // used to add a class last-child as css :last-child doesn't work with IE versions below IE8
        $('.featureBlock').each(function() {
            $(this).find('.featureBlockText li').last()
                .addClass('last-child');
        });

        // used to provide focus on the features container next to anchor tag
        $featureBlocks.find('.nextButton').click(function(){
            var $nextContainer = $(this).parents('.featureBlock').next();
            var headerHeight = $('.header_c').css('position') === 'fixed' ? $('.header_c').outerHeight() + 5 : 0;

            STG.fFocusOnContainer($nextContainer);
        });
    }

    // always expecting exactly three side-by-side-by-side
    var $teaserBlocks = $('.teaserBlock');

    if ($teaserBlocks.length) {
        if (OCMS.isIE8) {
            // after the wrapAll() IE8 shows <:section class="mainSection teaserSection">
            // http://pastie.org/935834#39 - problems with cloning HTML5 nodes
            // move teaserBlocks into their section.teaserSection an old-fashioned way for an old-fashioned browser
            $teaserBlocks.eq(0).before('<div class="teaserContainer_c ie8sux"></div>');
            $('.teaserContainer_c').html('<section class="mainSection teaserSection"></section>');
            var $tSection = $('section.teaserSection');
            console.log('length of $tSection: ', $tSection.length);
            $tSection.append($teaserBlocks);
        } else {
            $teaserBlocks.wrapAll('<div class="teaserContainer_c"><section class="mainSection teaserSection"></section></div>');
        }

        $teaserBlocks.find('.teaserBlockMoreLink').addClass('actionButton');

        // used to provide focus on the appropriate features block based on the teaser block index
        $teaserBlocks.find('a').click(function() {
            var teaserIndex = $(this).parents('.teaserBlock').index() + 1;
            var $featureClass = $('.feature' + teaserIndex);

            STG.fFocusOnContainer($featureClass);
        });

        $('.teaserSection').find('.teaserBlock').last().addClass('last-child');
    }
};	// fPrepareSolutionsPageTemplate


STG.fPrepareSearchResultsPageTemplate = function () {
    if ($('.result-set').length) {
        var q = $('.results-value').text() + ' results found for “' + $('.searched-value').text() + '”';
        $('.searched-label, .searched-value, .results-label, .results-value').remove();
        $('.result-set br').remove();
        $('.result-set').addClass('mainSection').prepend('<span class="searched">' + q + '</span>');
    }
};	// fPrepareSearchResultsPageTemplate


/**
 * set up handler for vertical scroll position causing a vertically compact main menu and provide the definition
 * of function STG.fSetCompactHeader that forces it to expand on demand on hover
 *
 * @note Must be called after STG.fDetectMobileLayout has set STG.isMobileLayout
 *
 * @param  {integer} nMinCompactOffset - vertical scroll px when the main nav menu becomes vertically compact
 * @return {function} STG.fSetCompactHeader - fSetCompactHeader(false) will expand the menu, regardless of document scroll position
 */
STG.fInitCompactHeader = function (nMinCompactOffset) {
    if($('body').hasClass('thinHeader')) {
        // skinnyHeader is a perma-compact header - it's all good on its own
        return;
    }

    if (!STG.isMobileLayout) {
        (function (nMinCompactOffset) {
            var bCompacted = false,
                $pg = $('.pg'),
                $menu = $('.mainNav_c .menu'),
                menuWidth = $menu.outerWidth() || 0,
                menuHeight = $menu.outerHeight() || 0;

            window.addEventListener('scroll', function (evt) {
                if ((!bCompacted && window.scrollY > nMinCompactOffset) || (bCompacted && window.scrollY < nMinCompactOffset)) {
                    bCompacted = !bCompacted;
                    $pg.toggleClass('compact', bCompacted);
                }
            });

            $('.header_c').on('mousemove', OCMS.waitForItToStop(function (evt) {
                var menuOffset = $menu.offset() || { left: 0, top: 0 },
                    relMouse = {
                        left: evt.pageX - menuOffset.left,
                        top: evt.pageY - menuOffset.top
                    };

                // only compact menu if mouse has settled outside of the main navigation menu
                if (relMouse.left < 0 || relMouse.top < 0 || relMouse.left > menuWidth || relMouse.top > menuHeight) {
                    // but wait! we also do not want a compact header to expand if the mouse lingers while over a menu item
                    // in a drop-down menu
                    // jQuery(…).is(':visible') is not working; must use .css(visibility), knowing that’s what the menu uses
                    // note: if no menu item is selected and the mouse moves into page body, that will trigger a last mousemove
                    // event and the header will be expanded (perhaps unexpected, but rare and justifiable)

                    var bOverMenu = false;
                    if (relMouse.left > 0 && relMouse.top > 0) {

                        $('.mainNav_c .level-1-list').each(function () {
                            if ($(this).css('visibility') === 'visible') {
                                bOverMenu = true;
                                return false;
                            }
                        });
                    }

                    if (!bOverMenu) {
                        STG.fSetCompactHeader(false);	// expand compact header
                    }
                }
            }, 500));

            STG.fSetCompactHeader = function (bCompact) {
                bCompacted = bCompact;			// we must update bCompacted to match menu header state
                $pg.toggleClass('compact', bCompacted);
            };
        }(nMinCompactOffset));
    } else {
        STG.fSetCompactHeader = function () {
            debug.info('fSetCompactHeader - noop');	// not expecting to be called in mobile context
        };
    }
}	// fInitCompactHeader


STG.fPreparePageTemplate = function() {
    var pgList = $('.pg').attr('class'),
        aPgClasses;

    // @!todo	rename/refactor for just two page templates
    debug.debug('raw page template class list: ' + pgList);
    if (pgList) {
        // remove pg and any class names that do not start with pg
        pgList = pgList.replace(/(^| )pg( |$)/, '').replace(/($| )(?!pg)\w*/g, '');
        debug.debug('    page template class list: ' + pgList);

        if (pgList.length) {
            aPgClasses = pgList.split(' ');

            var prepFunctions = {
                pgHome: STG.fPrepareHomePageTemplate
                // ,pgGeneral: STG.fPrepareSolutionsPageTemplate,
                // pgSearchResults: STG.fPrepareSearchResultsPageTemplate
            };

            $.each(aPgClasses, function(nIt, pgClass) {
                debug.debug('preparing ' + pgClass + ' page template…');

                f = prepFunctions[pgClass];
                if ($.isFunction(f)) {
                    f();
                } else {
                    debug.debug('\tno special preparation found for ' + pgClass);
                }
            });
        }
    }

    STG.fPrepareFadeInElements();

    // --- preparation common to ALL page templates ---

    // @!todo	rename/refactor for just two page templates
    STG.fPrepareSolutionsPageTemplate();
    STG.fPrepareSearchResultsPageTemplate();

    // menu titles should have href=# and should be ignored
    // should be only .root-level-item’s, but...?
    $('.mainNav_c a[href="#"], .footerMenu a[href="#"]').click(function(evt) {
        evt.preventDefault();
    });

    STG.fPrepareContactUsForm();

    STG.fAddBookmarkGoLinks();

    // look for .vidyard-meta URLs that need to be converted
    STG.fPrepareVidyardLinks();

    // site-wide search box needs placeholder text - ...or not: request from Tracy Sep-16
    //$('.ocms-search-input').hintText('Enter your search terms');
};	// fPreparePageTemplate

/**
 * intelligently handle elements that have the fade-in class
 *
 *	- for elements whose top is within the upper n% of the window, schedule them to
 *	  fade in on an incrementally delayed basis
 *	- for all others, set them up with a waypoints trigger
 *
 * @note
 *  - initial attempt always used waypoint, but proved unreliable as initial triggering of
 *    some waypoints was delayed until after a hero movie loaded
 *  - targets specific elements within the element that has .fade-in, but all elements are faded in
 *    at the same time once .fade-in has scrolled into view
 *
 * @param {tuple} options [optional] {
 *                        	startLine:         0…1 how far down the page do elements start to fade in
 *                        	incrementalDelay:  ms fade in start time delay for elements initially above the startLine
 *                        }
 * @return undefined
 */
STG.fPrepareFadeInElements = function (options) {
    var defaults = {
            startLine: 0.80,
            incrementalDelay: 250
        },
        nDelayIndex = 0;

    if (typeof options !== 'object') {
        options = {};
    }
    options = $.extend({}, defaults, options);

    // in this model, all descendent target elements are faded into view simultaneously,
    // even if some of those are still off-screen
    $('.fade-in').each(function (nIt, el) {
        var elementSelector = '.flexBlockListHeading, .image_c, .text_c';

        if ($(el).offset().top < window.innerHeight * options.startLine) {
            // debug.debug('delaying fade-in ', nIt, el);
            setTimeout(function () {
                // debug.info('––– top of page fading in ', el);
                $(el).find(elementSelector).css('opacity', 1);
            }, ++nDelayIndex * options.incrementalDelay);
        } else {
            // debug.debug('setting up waypoint for ', nIt, el);
            $(el).waypoint(function () {
                // debug.info('––– waypoint fading in ', this.element);
                $(this.element).find(elementSelector).css('opacity', 1);
                this.destroy();
            }, {offset: 100 * options.startLine + '%'});
        }
    });
};


/**
 * OrchestraCMS menus can’t specify an in-page hash bookmark value for its own page links, but this code lets the content author
 * indicate the bookmark name by adding special classes to the enclosing menu item. To specify the bookmark name, include a class
 * name of 'go' and another of 'go-ThePlace', where 'ThePlace' is the DOM id of some element on the destination page.
 * Typically this is used on markup that looks something like, say:
 *    li.level-1-item.go.go-ThePlace > a
 *  where '#ThePlace' needs to be appended to the href of the a, but it also should support the situation for a non-menu link where
 *  the markup is like:
 *    a.go.go-ThePlace
 *
 * @return undefined
 */
STG.fAddBookmarkGoLinks = function() {
    $('li.go, a.go').each(function(nIt, item) {
        var anchor = (item.tagName === 'LI' ? $(item).find('a').get(0) : item),
            match,
            href;

        // DOM element property className is a string, classList is the array
        if (anchor && this.className) {
            match = this.className.match(/go-[\w-]+/);

            if (match.length) {
                $(anchor).attr('href', $(anchor).attr('href') + '#' + match[0].slice(3));
                debug.info('bookmark added to: ', this);
            }
        }
    });
}	// fAddBookmarkGoLinks


STG.fSmoothScrollInterceptHandler = function(evt) {
    evt.preventDefault();
    debug.info('smooth scroll interception for ', this.href, this);

    // this is hide the mobile menu if it's open, and not have anything to click if it's not
    $('.mobileMenu .mobileMenuClose.mobileMenuToggler').click();

    var headerHeight = $('.header_c').css('position') === 'fixed' ? $('.header_c').outerHeight() + 5 : 0;
    OCMS.smoothScrollTo(this.href.slice(this.href.indexOf('#')), null, headerHeight);
};	// fSmoothScrollInterceptHandler


STG.fPrepareSmoothScrollLinks = function(evt) {
    // make and in-page links smooth scrollers
    var headerHeight = $('.header_c').css('position') === 'fixed' ? $('.header_c').outerHeight() + 5 : 0;
    OCMS.installSmoothScrollToForInPageLinks(null, headerHeight);

    // - menu based links will include a full href as well as a hash component, and we do not want the
    // browser to handle those (we would end up at the right position, but it’ll be after another page load)
    var urlPath = location.pathname + location.search;
    var reMatch = new RegExp(urlPath.replace('?', '\\?').replace('/', '\\/') + '#\\w+');
    $('a[href^="' + urlPath + '"]').each(function () {
        // stem-based attribute selector logic was a start, but will have included false positives
        if (this.href.match(reMatch)) {
            debug.debug('installing intercept handler for ', this.href, this)
            $(this).click(STG.fSmoothScrollInterceptHandler);
        } else {
            debug.info('not installing intercept handler for ', this.href, this);
        }
    });

    // and finally, if we’ve landing on this page with a hash, we have to scroll again to
    // compensate for our fixed header height
    OCMS.smoothScrollToLocationHash({nOffsetFromTop: headerHeight});
};	// fPrepareSmoothScrollLinks


STG.fInstallTapHoverSupport = function () {
    $('a').bind('touchstart', function (evt) {
        var $hoverElement = $(this);

        if ($hoverElement.parent().hasClass('root-level-item')) {
            $hoverElement = $hoverElement.parent();
        }

        if ($hoverElement.hasClass('hover')) {
            return true;
        } else {
            $('.hover').removeClass('hover');
            $hoverElement.addClass('hover');
            evt.preventDefault();
            return false;
        }
    });
};	// fInstallTapHoverSupport


/**
 * for each jQuery selector provided, if it has no children, remove the matching elements
 *
 * - note: each selector string may contain multiple selection rules, which will be handled together
 * - therefore a parameter of, for example, ['b, i'] is much different than ['b', 'i']
 * - also, note that jQuery’s children() method does not find text nodes, so if one of the selectors
 *   has one or more text nodes but no HTML elements, this method considers it empty!
 * - generic replacement for fRemoveEmptyColumns found in stantive-v2016.js
 *
 * @param  {Array of Strings or String} aSelector array of jQuery selector strings
 * @return undefined
 */
STG.fRemoveIfChildless = function(aSelector) {
    if (typeof aSelector === 'string') {
        aSelector = [aSelector];
    }

    $.each(aSelector, function (nIt, selector) {
        var $container = $(selector);

        if (!$container.children().length) {
            $container.remove();
        }
    });
};	// fRemoveIfChildless

STG.fDocReady = function() {
    try {
        OCMS.docReadyBegin(STG, 'stantive.js');

        if (!OCMS.inPageEditor) {
            STG.fDetectMobileLayout();
            //? if (STG.isMobileLayout) {
            // 	$('body').addClass('mobileLayout');
            // }
            STG.fDetectTouchDevice();
            //? if (STG.isTouchDevice) {
            // 	$('body').addClass('touchDevice');
            // }

            // for now we're assuming animation effects will be either not noticed or
            // are too slow to do on mobile devices (clearly, this will not always be true, but so be it)
            STG.doContentAnimations = !(STG.isMobileLayout || STG.isTouchDevice);

            // debug.debug('setting STG.doContentAnimations to false for testing purposes');
            // STG.doContentAnimations = false;

            STG.fPreparePageTemplate();
            STG.fInitCompactHeader(300);

            // .heroBannerBackgroundLeft/Right are 100% height, but may be empty. Remove them if both empty.
            // similarly with .heroBannerBackgroundFull
            STG.fRemoveIfChildless([
                '.heroBannerbackgroundLeft, .heroBannerBackgroundRight',
                '.heroBannerBackgroundFull'
            ]);

            $('.mobileMenuToggler').click(function(evt) {
                var classes = 'mobileMenu';

                if (!$('.pg').is('.pgTwoColumn')) {
                    classes += ' noScroll';
                }

                $('html').toggleClass(classes);
                if ($('html').hasClass('mobileMenu')) {
                    $('.hdrRight').animate({bottom: '0%'}, 500);
                } else {
                    $('.hdrRight').css({bottom: ''});
                }
            });

            // also handles landing on a page with a hash provided
            STG.fPrepareSmoothScrollLinks();

            // not all touch devices do the iOS trick of tap looking like :hover
            // ...well, it seems that in Android 4.x Chrome DOES make it work; old "Internet" not such much, but we'll leave that behind at least for now
            //STG.fInstallTapHoverSupport();
        }   // !isPageEditor

        OCMS.docReadyEnd(STG, 'stantive.js');
    } catch (ex) {
        OCMS.logException(ex, '𝍐𝍐 stantive.js');
    }
};

$(document).ocmsDynamicLoadFinished(STG.fDocReady);
// with this JS file at the bottom of the page template, there should be no need to wait for doc.ready(), just run it
//STG.fDocReady();
