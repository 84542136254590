require('../lib/waypoints/jquery.waypoints.min');
require('../lib/ocms-helper/js/ocms-extensions');
require('./legacy/stantive');

// require('./components/case');
require('./components/crossSiteLoader');
require('./components/devDocument');
require('./components/dialog');
// require('./components/idea');
require('./components/leftNav');
// require('./components/lms');
require('./components/loginForm');
// require('./components/myAccount');
require('./components/networkSwitch');
require('./components/ocmsForm');
require('./components/packageIndicator');
require('./components/searchForm');
require('./components/smartBreadcrumb');
// require('./components/syntaxHighlighter');
require('./components/youtubePlaylist');