var parameters = {};

function reset() {
    window.location.search.substr(1).split('&').map(function(param) {
        return param.split('=');
    }).forEach(function(param) {
        parameters[decodeURIComponent(param[0])] = decodeURIComponent(param[1]);
    });
}

reset();

module.exports = {
    getParameter: function(name) {
        return parameters[name];
    },

    reset: reset,
};