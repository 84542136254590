module.exports = (function() {
    var idNumber = 0;

    return function(prefix) {
        prefix = prefix || '';
        idNumber ++;

        if (prefix) {
            return prefix + '_' + idNumber;
        } else {
            return idNumber;
        }
    }
})();
