module.exports = {
    set: function(name, value, expiry, domain) {
        return _setCookie(name, value, expiry, domain, true);
    },

    setWithPath: function(name, value, expiry, domain) {
        return _setCookie(name, value, expiry, domain, false);
    },

    remove: function(name, domain) {
        return _setCookie(name, 'deleted', -1, domain, true);
    },

    get: _getCookie,
};

function _setCookie (name, value, expiry, domain, bIsRootCookie) {
    // expiry [optional]    numeric - number of days before cookie expires
    //                      Date - expiration date as Date
    //                      string - expiration date as UTC string
    //                      if expiry is empty, it's a session cookie
    // domain [optional]    specify sub-domain-less domain string for cookie to span sub-domains
    // bRootCookie [optional]   truthy value => set a root-level cookie

    var expiryDate,
        expiresAt = '; expires=',
        cookie = name + '=' + encodeURIComponent(value);

    if (expiry) {
        if (typeof expiry === 'number') { // let 0 mean session cookie too
            expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + expiry);
            expiresAt += expiryDate.toUTCString();
        } else if (typeof expiry === 'object') { // assumes Date! (could check)
            expiresAt += expiry.toUTCString();
        } else if (typeof expiry === 'string') {
            expiresAt += expiry;
        }
    } else {
        // 0, '', and null all go through here as well as undefined
        expiresAt = '';
    }

    // previous implementation omitted path for non-root cookies, surely incorrectly, and was likely confused with another spec
    cookie += expiresAt + '; path=' + (bIsRootCookie ? '/' : window.location.pathname);
    if (domain) {
        cookie += ';domain=' + domain;
        if (location.href.indexOf(domain) < 0) {
            debug.error('setCookie’s domain parameter is not within URL of current page: ' + domain);
            // ...and setCookie() will fail
        }
    }

    //debug.Info('setting cookie ' + name + ' as “' + cookie + '”');
    document.cookie = cookie;
}  // _setCookie

function _getCookie (name) {
    var cookieValue;

    $.each(document.cookie.split(';'), function (nIt, thisCookie) {
        var nEq = thisCookie.indexOf('=');
        if (name === thisCookie.slice(0, nEq).trim()) {
            cookieValue = decodeURIComponent(thisCookie.slice(nEq + 1));
            return false;
        }
    });

    return cookieValue;
}   // getCookie
