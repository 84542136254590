module.exports = {
    initPackageIndicator: initPackageIndicator
};

$(function() {
    $('[data-package]').each(function(i, e) {
        initPackageIndicator(e);
    });
});

function initPackageIndicator(element) {
    var $packageIndicator = $('<div class="packageIndicator"></div>').text('Package ' + element.dataset.package);
    $(element).prepend($packageIndicator);
}