module.exports = function doServiceRequest_crossSite(sname, a, b) {
    var cmsData = $(document).data('cms');
    var shouldOverride = (sname !== cmsData.site_name);

    var oldSite, oldContext;

    if (shouldOverride) {
        oldSite = cmsData.site_name;
        oldContext = cmsData.ocms_context;

        cmsData.site_name = sname;
        cmsData.ocms_context = undefined;
    }

    doServiceRequest(a, b);

    if (shouldOverride) {
        cmsData.site_name = oldSite;
        cmsData.ocms_context = oldContext;
    }
};