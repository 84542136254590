$(function() {
    var $active = $('.leftNav_c .active');

    $('[data-smartbreadcrumb]').each(function(i, breadcrumb) {
        var $e;
        var $breadcrumb = $(breadcrumb);
        var separatorHtml = '<span class="breadcrumb-menu-divider">&nbsp;' + $breadcrumb.attr('data-separator') + '&nbsp;</span>'
        var homeSeparator = $active.length > 0 ? separatorHtml : '';

        if($active.length == 0) {
            if(window.CMS.page_mode === 'prev') {
                $e = '<span style="color:red !important">This page doesn\'t appear to be in a menu</span>';
            }
        } else {
            $e = $('<span>').append(
                $('<span class="breadcrumb-home">').append(
                    $('<a>').attr('href', $('.logo_c > a').attr('href')).text($breadcrumb.attr('data-hometext')),
                    homeSeparator
                )
            );

            $active.each(function(i) {
                var $link = $('> a', this);

                if(i + 1 == $active.length) {
                    $e.append(
                        $('<span>').append(
                            $('<span class="breadcrumb-menu-active">').text($link.text())
                        )
                    );
                } else {
                    $e.append(
                        $('<span>').append(
                            $('<a class="breadcrumb-menu">').attr('href', $link.attr('href')).text($link.text()),
                            separatorHtml
                        )
                    );
                }
            });
        }

        $breadcrumb.append($e);
    });
});